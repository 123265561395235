import { computed } from "vue";
import { useStore } from "vuex";

const useDeportes = () => {
  const store = useStore();

  const getDeporte = async () => {
    const resp = await store.dispatch("deportes/getDeporte");
    return resp;
  };


  const createDeporte = async (obj) => {
    const resp = await store.dispatch("deportes/createDeporte", obj);
    return resp;
  };
  const editDeporte = async (obj) => {
    const resp = await store.dispatch("deportes/editDeporte", obj);
    return resp;
  };
  const deleteDeporte = async (obj) => {
    const resp = await store.dispatch("deportes/deleteDeporte", obj);
    return resp;
  };

  return {
    getDeporte,  
    createDeporte,
    editDeporte,
    deleteDeporte,
    deportes: computed(() => store.getters["deportes/getDeportes"]),
  };
};

export default useDeportes;
