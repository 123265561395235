<template>
  <h3 class="text-light mt-3">Deportes</h3>
  <div class="text-end mt-2 mb-2">
    <button class="myButtonAceptar" @click="agregarDeporte">Agregar</button>
  </div>

  <div class="card table-responsive-sm" style="height: 500px; overflow: auto">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Deporte</th>
          <th scope="col">Imagen</th>
        </tr>
      </thead>
      <tbody v-for="d of deportes" v-bind:key="d.id">
        <tr style="cursor: pointer">
          <td @click="select(d)">{{ d.nombre }}</td>
          <td @click="select(d)">{{ d.imagen }}</td>

          <td>
            <button class="btnEliminarTable" @click="eliminarDeportes(d)">
              <i style="color: #fff" class="far fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject } from "vue-demi";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import useDeportes from "../composable/useDeportes";
export default {
  props: ["deportes"],
  setup() {
    const btn = inject("btn");
    const objDerpotes = inject("objDerpotes");
    const store = useStore();
    const { deleteDeporte,getDeporte } = useDeportes();

    const select = (d) => {
      btn.value = true;
      objDerpotes.id = d.id;
      objDerpotes.nombre = d.nombre;
      objDerpotes.imagen = d.imagen;
      objDerpotes.status = d.status;
    };

    const eliminarDeportes = (e) => {
      Swal.fire({
        title: "¿Seguro que desea eliminar este registro?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          store.commit("showLoader");
          objDerpotes.id = e.id; 
          await deleteDeporte(objDerpotes);
          await getDeporte();
          store.commit("hideLoader");
        }
      });
    };

    const agregarDeporte = () => {
      btn.value = true;
      objDerpotes.id = null;
      objDerpotes.nombre = "";
      objDerpotes.imagen = null;
      objDerpotes.status = null;
    };

    return {
      select,
      eliminarDeportes,
      agregarDeporte,
    };
  },
};
</script>

<style>
</style>