<template>
  <div class="container">
    <div class="row">
      <form @submit.prevent="onSubmit" class="deportesEstilo">
        <div class="text-end text-light">
          <Cerrar />
        </div>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <label class="text-light">Deporte</label>
            <input
              type="text"
              class="form-control"
              v-model="objDerpotes.nombre"
            />
          </div>
          <div class="col-md-4">
            <label class="text-light">Imagen</label>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                v-model="objDerpotes.imagen"
                disabled
              />
              <button
                class="btn btn-danger"
                type="button"
                @click="limpiarInput()"
              >
                X
              </button>
              <button
                class="btn btn-success"
                type="button"
                @click="pegarImagen"
              >
                <i class="fas fa-paste"></i>
              </button>
              <GaleriaBtn />
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-4 mb-4">
          <div class="col-md-2">
            <button class="myButtonAceptar" v-if="!objDerpotes.id">
              Guardar
            </button>
            <button class="myButtonAceptar" v-else>Editar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { inject, onBeforeUnmount, onMounted } from "vue-demi";

// import { useStore } from "vuex";
import Cerrar from "../../../components/button/Cerrar.vue";
import useDeportes from "../composable/useDeportes";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import GaleriaBtn from "../../../components/button/GaleriaBtn.vue";
import useUtil from "../../../use/useUtil";

export default {
  components: {
    Cerrar,
    GaleriaBtn,
  },
  setup() {
    const btn = inject("btn");
    // const store = useStore();
    const objDerpotes = inject("objDerpotes");
    const { createDeporte, getDeporte, editDeporte } = useDeportes();
    const store = useStore();
    const { onPegarTexto } = useUtil();
    onMounted(() => {});

    const cerrar = () => {
      btn.value = false;
    };

    const onSubmit = async () => {
      store.commit("showLoader");
      let resp = "";
      if (objDerpotes.id) {
        resp = await editDeporte(objDerpotes);
      } else {
        resp = await createDeporte(objDerpotes);
      }
      await getDeporte();
      Swal.fire("Excelente!", resp.message, "success");
      store.commit("hideLoader");
      cerrar();
    };

    onBeforeUnmount(() => {
      btn.value = false;
    });

    const pegarImagen = async () => {
      objDerpotes.imagen = "";
      objDerpotes.imagen = await onPegarTexto();
    };

    const limpiarInput = () => {
      objDerpotes.imagen = "";
    };

    return {
      cerrar,
      objDerpotes,
      onSubmit,
      pegarImagen,
      limpiarInput,
    };
  },
};
</script>

<style scoped>
.deportesEstilo {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  /* padding: 20px;
  width: 80em; */
  /* margin: auto; */
}

@media (max-width: 1000px) {
  .deportesEstilo {
    width: 100%;
  }
}
</style>