<template>
    <div class="container-fluid">
   
    <div class="row">
      <div v-if="!btn" class="col-12">
        <DeportesTable :deportes="deportes" />
      </div>
      <div v-if="btn" class="col-12"><DeportesForm /></div>
    </div>
  </div>
</template>

<script>
import {  inject, onMounted, provide, reactive } from 'vue-demi';
import DeportesTable from '../components/DeportesTable.vue';
import DeportesForm from '../components/DeportesForm.vue';
import useDeportes from '../composable/useDeportes';

export default {
    components: { DeportesTable, DeportesForm },
    setup() {
        const btn = inject("btn");
        const {getDeporte,deportes} = useDeportes();

        const objDerpotes = reactive({
            id: null,
            nombre: "",
            imagen: null,
            status: null,           
        })

        provide("objDerpotes",objDerpotes)

        onMounted(async()=>{
           await getDeporte();
        })

        return {
            btn,
            getDeporte,
            deportes           
        };
    },
}
</script>

<style>

</style>